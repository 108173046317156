.amountInvested {
  background: #2a2a2a;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  padding: 0px 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
}
.title {
  color: #fafafa;
  font-size: 18px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  white-space: nowrap;
}
.wrapper {
  padding: 22px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 18px;
}
.amountContainer {
  display: flex;

  align-items: center;
  gap: 30px;
}
.investedAmount {
  color: #1ee0ac;
  font-size: 24px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.prifitAmount {
  color: #fafafa;
  font-size: 18px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.currency {
  color: #1ee0ac;
}
.text {
  color: #fff;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
}

.bottomBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
  width: 100%;
  border-top: 1px solid #39393c;
  padding: 22px 0;
}
.box {
  display: flex;
  align-items: center;
  gap: 12px;
}
.icon {
  width: 24px;
}
.bottomText {
  color: #1ee0ac;
  font-size: 15px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
